/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

enum SeverityColors {
    error = '#ff3354',
    warn = '#ffbb00',
    success = '#00c800'
}

enum StatusBGColor {
    approved = 'rgba(221, 246, 224, 1)',
    declined = 'rgba(255, 204, 212, 1)',
    'waiting-for-applicant' = 'rgba(254, 249, 233, 1)',
    'waiting-for-employer' = 'rgba(254, 249, 233, 1)',
    canceled = 'rgba(242, 241, 241, 1)',
    expired = 'rgba(242, 241, 241, 1)'
}

enum StatusColor {
    approved = 'rgba(0, 200, 0, 1)',
    declined = 'rgba(255, 51, 84, 1)',
    'waiting-for-applicant' = 'rgba(255, 190, 0, 1)',
    'waiting-for-employer' = 'rgba(255, 190, 0, 1)',
    canceled = 'rgba(106, 106, 106, 1)',
    expired = 'rgba(106, 106, 106, 1)'
}

enum EmployeeStatusColor {
    active = 'rgba(0, 200, 0, 1)',
    exited = 'rgba(106, 106, 106, 1)',
    'not--activated' = 'rgba(106, 106, 106, 1)'
}

enum EmployeeStatusBgColor {
    active = 'rgba(221, 246, 224, 1)',
    exited = 'rgba(242, 241, 241, 1)',
    'not-activated' = 'rgba(242, 241, 241, 1)'
}

enum PaletteColors {
    primaryMain = 'rgba(50, 50, 50, 1)',
    primaryLight = 'rgba(242, 241, 241, 1)',
    secondaryMain = 'rgba(254, 112, 98, 1)',
    secondaryLight = 'rgba(254, 198, 192, 1)',
    infoMain = 'rgba(166, 99, 202, 1)'
}

export { SeverityColors, StatusBGColor, StatusColor, PaletteColors, EmployeeStatusColor, EmployeeStatusBgColor };
