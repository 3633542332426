{
    "name": "emplu-admin-dashboard",
    "version": "2.0.0",
    "scripts": {
        "ng": "ng",
        "start": "ng serve",
        "start:stage-dev": "ng serve -c stage-dev",
        "start:prod-dev": "ng serve -c prod-dev",
        "start:dev": "ng serve -c develop",
        "start:stage": "ng serve -c staging",
        "start:prod": "ng serve -c production",
        "build": "ng build",
        "build:develop": "npm run config:setBuildTime && ng build --configuration develop",
        "build:staging": "npm run config:setBuildTime && ng build --configuration staging",
        "build:production": "npm run config:setBuildTime && ng build --configuration production",
        "watch": "ng build --watch --configuration develop",
        "test": "ng test",
        "test:coverage": "ng test --code-coverage",
        "cypress:verify": "cypress verify",
        "cypress:info": "cypress info",
        "cypress:open": "cypress open",
        "cypress:run": "cypress run",
        "prepare": "husky",
        "check-types": "tsc --pretty --noEmit",
        "check-format": "prettier --check .",
        "format": "prettier --write .",
        "lint": "ng lint",
        "lint:fix": "ng lint --fix",
        "e2e": "ng e2e",
        "config:setBuildTime": "node scripts/build_time.js"
    },
    "private": true,
    "type": "module",
    "engines": {
        "node": "^20.9.0"
    },
    "dependencies": {
        "@angular/animations": "^18.2.11",
        "@angular/cdk": "^18.2.13",
        "@angular/common": "^18.2.11",
        "@angular/compiler": "^18.2.11",
        "@angular/core": "^18.2.11",
        "@angular/forms": "^18.2.11",
        "@angular/platform-browser": "^18.2.11",
        "@angular/platform-browser-dynamic": "^18.2.11",
        "@angular/router": "^18.2.11",
        "@ngx-translate/core": "^15.0.0",
        "@ngx-translate/http-loader": "^8.0.0",
        "@sbfw/core": "^0.0.6",
        "angular-svg-icon": "^18.0.2",
        "file-saver-es": "^2.0.5",
        "lodash-es": "^4.17.21",
        "luxon": "^3.5.0",
        "md5": "^2.3.0",
        "nanoid": "^5.0.8",
        "ngx-avatars": "^1.8.0",
        "ngx-cookie-service": "^18.0.0",
        "ngx-echarts": "^18.0.0",
        "ngx-extended-pdf-viewer": "^21.4.3",
        "primeflex": "^3.3.1",
        "primeicons": "^7.0.0",
        "primeng": "^17.18.12",
        "quill": "^2.0.2",
        "replace-in-file": "^8.2.0",
        "rxjs": "^7.8.1",
        "ts-md5": "^1.3.1",
        "tslib": "^2.7.0",
        "xlsx": "^0.18.5",
        "zone.js": "^0.14.10"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^18.2.11",
        "@angular-eslint/builder": "^18.3.1",
        "@angular-eslint/eslint-plugin": "^18.3.1",
        "@angular-eslint/eslint-plugin-template": "^18.3.1",
        "@angular-eslint/schematics": "^18.3.1",
        "@angular-eslint/template-parser": "^18.3.1",
        "@angular/cli": "^18.2.11",
        "@angular/compiler-cli": "^18.2.11",
        "@ngneat/spectator": "^19.0.0",
        "@stylistic/eslint-plugin": "^2.8.0",
        "@types/file-saver-es": "^2.0.3",
        "@types/jasmine": "^5.1.4",
        "@types/lodash-es": "^4.17.12",
        "@types/luxon": "^3.4.2",
        "@types/md5": "^2.3.5",
        "@types/node": "^20.11.5",
        "@types/quill": "^2.0.14",
        "@typescript-eslint/eslint-plugin": "^8.7.0",
        "@typescript-eslint/parser": "^8.7.0",
        "cypress": "^13.15.0",
        "cypress-file-upload": "^5.0.8",
        "eslint": "^9.14.0",
        "eslint-config-prettier": "^9.1.0",
        "eslint-plugin-angular": "^4.1.0",
        "eslint-plugin-cypress": "^3.5.0",
        "husky": "^9.1.6",
        "jasmine-core": "~5.4.0",
        "karma": "~6.4.4",
        "karma-chrome-launcher": "~3.2.0",
        "karma-coverage": "~2.2.1",
        "karma-jasmine": "~5.1.0",
        "karma-jasmine-html-reporter": "~2.1.0",
        "lint-staged": "~15.2.10",
        "prettier": "^3.3.3",
        "pretty-quick": "^4.0.0",
        "typescript": "^5.4.5"
    },
    "exports": {
        "./schematics/*": {
            "default": "./schematics/*.js"
        },
        "./package.json": {
            "default": "./package.json"
        },
        ".": {
            "types": "./core.d.ts",
            "esm2020": "./esm2020/core.mjs",
            "es2020": "./fesm2020/core.mjs",
            "es2015": "./fesm2015/core.mjs",
            "node": "./fesm2015/core.mjs",
            "default": "./fesm2020/core.mjs"
        },
        "./testing": {
            "types": "./testing/testing.d.ts",
            "esm2020": "./esm2020/testing/testing.mjs",
            "es2020": "./fesm2020/testing.mjs",
            "es2015": "./fesm2015/testing.mjs",
            "node": "./fesm2015/testing.mjs",
            "default": "./fesm2020/testing.mjs"
        }
    }
}
